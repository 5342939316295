import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { ApplicationStorageService } from '../storage';

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private readonly storage = inject(ApplicationStorageService);

  private _theme$ = new BehaviorSubject<Theme | 'high contrast' | undefined>(undefined); // <- initial theme
  themeChanged = this._theme$.asObservable();
  store = true;
  storeAs = 'theme';

  theme = toSignal(this._theme$);
  isDarkMode = computed(() => this.theme() === Theme.DARK);
  isLightMode = computed(() => this.theme() === Theme.LIGHT);

  get colorScheme(): Theme {
    const mode = this.theme();
    const prefers = window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.DARK : Theme.LIGHT;
    // prettier-ignore
    switch (mode) {
      case Theme.DARK:
        return Theme.DARK;
      case Theme.LIGHT:
        return Theme.LIGHT;
      default:
        return prefers;
    }
  }

  /**
   * Call this to initiate automatic event handling based on user choices in the underlying OS.
   * This should usually be called in the initial bootstrapped component of your application.
   *
   * If this is not called, you have to run `setTheme` manually in order to properly inform
   * the DOM and any listeners.
   */
  listen(store = this.store, storeAs = this.storeAs) {
    this.store = store;
    this.storeAs = storeAs;
    // Initially check if dark mode is enabled on system
    const darkModeOn = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    let value = darkModeOn ? Theme.DARK : Theme.LIGHT;
    if (this.store) value = this.storage.getItem(this.storeAs, value);
    this.setTheme(value);

    // Watch for changes of the preference
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e: MediaQueryListEvent) => this.setTheme(e.matches ? Theme.DARK : Theme.LIGHT));
  }

  /**
   * Sets the DOM and inform subscribers of the change
   *
   * @param mode
   * @returns
   */
  setTheme(mode: Theme | 'high contrast') {
    if (!mode || this._theme$.value === mode) return;

    // An actual change. Inform subscribers and set DOM.
    this._theme$.next(mode);
    if (this.store) this.storage.setItem(this.storeAs, mode);

    // Both the <body> ...
    document.body.dataset.theme = mode;
    // ... and the <html> element should be informed of the change
    const root = document.querySelector('html');
    root?.classList.remove(Theme.LIGHT, Theme.DARK, 'contrast');

    const prefers = window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.DARK : Theme.LIGHT;
    // prettier-ignore
    switch (mode) {
      case Theme.DARK:
        root?.classList.add(Theme.DARK);
        root?.setAttribute('data-ag-theme-mode', 'dark');
        break;
      case Theme.LIGHT:
        root?.classList.add(Theme.LIGHT);
        root?.setAttribute('data-ag-theme-mode', 'light');
        break;
      case 'high contrast':
        root?.classList.add('contrast');
      // eslint-disable-next-line no-fallthrough
      default:
        root?.classList.add(prefers);
    }
  }
}
